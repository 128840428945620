import React from 'react'

export const HEAD_OFFICE = 'head';
const HeadOfficeMap = ({}) => (
  <iframe width="100%" height="100%" id="gmap_canvas"
      src="https://maps.google.com/maps?q=%EC%A7%80%EC%A0%95%EB%A9%B4%20%EA%B8%B0%EC%97%85%EB%8F%84%EC%8B%9C%EB%A1%9C%20200&t=&z=17&ie=UTF8&iwloc=&output=embed"
      frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
)

export default HeadOfficeMap;
