import React from 'react'
import { useTranslation } from 'react-i18next';
import BoldBase from '../../tags/boldBase';
import { Korean } from '../../../../i18n'
import RndOfficeMap, { RND_OFFICE } from './rndOffice';
import HeadOfficeMap, { HEAD_OFFICE } from './headOffice';

const CompanyLocation = () => {
  const { t } = useTranslation();
  return (
    <article className="w-full lg:max-w-7xl mx-auto flex flex-col">
      <LocationMap title={t("contact.locationTitle.headOffice")} type={HEAD_OFFICE}/>
      <HeadOfficeLocationAddress />
      <LocationMap title={t("contact.locationTitle.lab")} type={RND_OFFICE}/>
      <LocationAddress />
    </article>
  )
}

const LocationTitle = ({ title }) => {
  return (<h4 className='mt-16 font-bold text-3xl leading-[62px] text-gray-080'>{title}</h4>)
}

const LocationMap = ({ title, type=RND_OFFICE }) => {

  return (
    <>
      <LocationTitle title={title} />
      <section className="w-full h-[474px] mt-4">
        <div className="w-full h-full">
          {
            type === RND_OFFICE
            ? <RndOfficeMap />
            : <HeadOfficeMap />
          }
        </div>
      </section>
    </>
  )
}
const LocationAddress = () => {
  const { t, i18n } = useTranslation();
  return (
    <section className="w-full mt-8 flex flex-col lg:flex-row">
      <div className="flex-1 flex flex-col">
        <AddressTitle>{t("contact.addressInfo")}</AddressTitle>
        <div className="flex flex-col space-y-3">
          {
            i18n.language === Korean
            && <AddressInfo title="구 주소" content="서울특별시 강남구 역삼동 830-69 7층" />
          }
          <AddressInfo title={t("contact.address")} content={t("contact.addressCon.lab")} />
          <AddressInfo type="tel" title="TEL" content="+82 2-6085-1105" />
          <AddressInfo title="FAX" content="+82 50-4287-9018" />
          <AddressInfo type="email" title="MAIL" content="contact@breathings.co.kr" />
        </div>
      </div>
      <div className="mt-12 lg:mt-0 flex-1 flex flex-col">
        <AddressTitle>{t("contact.transportInfo")}</AddressTitle>
        <AddressInfo title={t("contact.subway")} content={t("contact.subwayExit")} />
      </div>
    </section>
  )
}

const HeadOfficeLocationAddress = () => {
  const { t, i18n } = useTranslation();
  return (
    <section className="w-full mt-8 flex flex-col lg:flex-row">
      <div className="flex-1 flex flex-col">
        <AddressTitle>{t("contact.addressInfo")}</AddressTitle>
        <div className="flex flex-col space-y-3">
          <AddressInfo title={t("contact.address")} content={t("contact.addressCon.headOffice")} />
        </div>
      </div>
    </section>
  )
}

const AddressTitle = ({ children }) => (
  <span className="text-base mb-8 font-bold text-gray-080">{children}</span>
)
const AddressInfo = ({ title, content, type = "" }) => {
  const ClassName = "flex-1 text-base font-light break-words text-gray-080 whitespace-pre-line";
  return (
    <div className="flex flex-row">
      <BoldBase className="w-[143px]">{title}</BoldBase>
      {
        type === "tel"
          ? <a href={`tel:${content}`} className={ClassName}>{content}</a>
          : type === "email"
            ? <a href={`mailto:${content}`} className={ClassName}>{content}</a>
            : <span className={ClassName}>{content}</span>
      }
    </div>
  )
}
export default CompanyLocation;