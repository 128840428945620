import React from 'react'
import ContactIndex from "../components/pages/Contact";
import Main from "../layout/main";

const ContactPage = (props) => (

  <Main title="Contact Us" {...props}>
    <ContactIndex />
  </Main>
)

export default ContactPage;