import React from 'react'
import DownloadIcon from '../../icons/download';
import CompanyLocation from './location';
import H1 from '../../tags/h1';
import Body1 from '../../tags/body1';
import { useTranslation } from 'react-i18next';
import BreathingsIntroduce from '../../../catalog/Breathings.pdf';

const ContactIndex = ({}) => (
  <ContarctLayout>
    <ContactSection />
    <CompanyLocation />
  </ContarctLayout>
  
)
const ContarctLayout = ({children}) => (
  <article className="relative w-full lg:max-w-7xl mx-auto px-4 md:px-page-x-sm lg:px-page-x py-page-y flex flex-col justify-center">
    {children}
  </article>
)
const ContactSection = () => (
  <div className="w-full justify-center">
    <ContactHeader />
  </div>
)

const ContactHeader = () => {
  const {t} = useTranslation();
  return (
    <section className="w-full flex flex-col md:flex-row items-start">
      <H1 className="w-[250px] font-metropolis">Contact Us</H1>
      <div className="flex-1 w-full md:w-fit flex flex-col lg:flex-row items-start md:items-end lg:items-start">
        <Body1 className="min-w-[359px] max-w-[359px] flex-1 mt-9 md:mt-0 md:ml-9">{t("contact.desc")}</Body1>
        <DownloadCompanyIntroduce text={t("companyIntroDown")}/>
      </div>
    </section>
  )
}

const DownloadCompanyIntroduce = ({text}) => (
  <div className="mt-9 lg:mt-0 w-full md:w-fit flex justify-center">
    <a href={BreathingsIntroduce} download className="w-full md:w-[307px] max-w-[307px] flex flex-row py-[14px] justify-center items-center border rounded border-main text-main" >
      <span className="text-base">{text}</span>
      <DownloadIcon className="ml-5" stroke="#4E79E7"/>
    </a>
  </div>
)
export default ContactIndex;